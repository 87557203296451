<template>
  <div class="row q-pl-sm q-pr-sm q-gutter-md">
    <span
      class="col text-bold"
      v-for="(header, index) in headers"
      :key="`header-${index}`"
    >
      {{ header }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
  },
  name: "header",
};
</script>

<style scoped></style>
