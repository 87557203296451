<template>
  <pro-menu-layout>
    <div class="q-ma-md">
      <q-card>
        <q-card-section>
          <div class="row justify-between items-center">
            <h4 class="text-primary">{{ $t("Form.Section.MeetingReq") }}</h4>
            <pro-button
              v-if="allowAddMeeting"
              label="System.Button.AddMeetingRequest"
              icon="PROSmart-Add"
              :to="addMeetingRoute"
            />
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <Header :headers="headers" style="margin-right: 62px" />
          <q-separator spaced />
          <div
            class="row grey-on-hover q-pa-sm"
            style="word-break: break-all"
            v-for="(
              {
                id: meetingId,
                purpose,
                tendererName,
                location,
                startDate,
                endDate,
              },
              id
            ) in data.meetingRequests"
            :key="`mr-${id}`"
          >
            <div class="col row items-center q-gutter-md">
              <span class="col">{{ purpose }}</span>
              <span class="col">{{ tendererName }}</span>
              <span class="col">{{ location }}</span>
              <span class="col">{{ formatDate(startDate) }}</span>
              <span class="col">{{ formatDate(endDate) }}</span>
            </div>
            <div class="col-auto row items-center">
              <pro-button
                label="System.Button.View"
                icon="PROSmart-Docs"
                :to="getViewMeetingRequestDetail(meetingId)"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </pro-menu-layout>
</template>

<script>
import Header from "./header.vue";
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProButton from "@/components/PROSmart/ProButton";

export default {
  name: "DocumentViewMeetingRequest",
  components: { ProButton, Header, ProMenuLayout },
  props: {
    documentStatus: Number,
  },
  data() {
    return {
      headers: [
        this.getRes("Form.Field.Purpose"),
        this.getRes("Form.Field.TendererName"),
        this.getRes("Form.Field.Location"),
        this.getRes("Form.Field.Starttime"),
        this.getRes("Form.Field.Endtime"),
      ],
      data: {
        meetingRequests: [],
      },
      addMeetingRoute: null,
      addMeetingWorkflowInfo: {},
      allowAddMeetingStatus: [50, 80],
    };
  },

  computed: {
    allowAddMeeting() {
      return (
        this.addMeetingRoute &&
        this.addMeetingWorkflowInfo.visible &&
        this.addMeetingWorkflowInfo.allowCreate &&
        this.allowAddMeetingStatus.includes(this.documentStatus)
      );
    },
  },

  methods: {
    formatDate(date = new Date()) {
      return this.$proSmart.common.getFormattedDate(date);
    },

    getViewMeetingRequestDetail(meetingId) {
      return {
        name: "DocumentViewMeetingRequestDetail",
        params: {
          meetingId,
        },
      };
    },
  },

  async created() {
    const { id: tenderId } = this.$route.params;

    this.$proSmart.documentUi.getMeetingRequestByTenderId(this, tenderId).then(
      (res) =>
        (this.data.meetingRequests = res.map((meetings) => {
          return { ...meetings };
        }))
    );
    this.$proSmart.tender
      .getWorkflowCode(this, tenderId, ["add_meeting_request"])
      .then(([addMeeting]) => {
        this.addMeetingWorkflowInfo = addMeeting;
        this.addMeetingRoute = {
          name: "DocumentViewForm",
          params: {
            mode: "Edit",
            code: addMeeting.workflowCode,
          },
        };
      });
  },
};
</script>

<style scoped lang="scss">
.grey-on-hover {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s;
    opacity: 0;
    z-index: 0;
  }

  &:hover:before {
    opacity: 1;
  }
}
</style>
