var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pro-menu-layout',[_c('div',{staticClass:"q-ma-md"},[_c('q-card',[_c('q-card-section',[_c('div',{staticClass:"row justify-between items-center"},[_c('h4',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.$t("Form.Section.MeetingReq")))]),(_vm.allowAddMeeting)?_c('pro-button',{attrs:{"label":"System.Button.AddMeetingRequest","icon":"PROSmart-Add","to":_vm.addMeetingRoute}}):_vm._e()],1)]),_c('q-separator'),_c('q-card-section',[_c('Header',{staticStyle:{"margin-right":"62px"},attrs:{"headers":_vm.headers}}),_c('q-separator',{attrs:{"spaced":""}}),_vm._l((_vm.data.meetingRequests),function(ref,id){
            var meetingId = ref.id;
            var purpose = ref.purpose;
            var tendererName = ref.tendererName;
            var location = ref.location;
            var startDate = ref.startDate;
            var endDate = ref.endDate;
return _c('div',{key:("mr-" + id),staticClass:"row grey-on-hover q-pa-sm",staticStyle:{"word-break":"break-all"}},[_c('div',{staticClass:"col row items-center q-gutter-md"},[_c('span',{staticClass:"col"},[_vm._v(_vm._s(purpose))]),_c('span',{staticClass:"col"},[_vm._v(_vm._s(tendererName))]),_c('span',{staticClass:"col"},[_vm._v(_vm._s(location))]),_c('span',{staticClass:"col"},[_vm._v(_vm._s(_vm.formatDate(startDate)))]),_c('span',{staticClass:"col"},[_vm._v(_vm._s(_vm.formatDate(endDate)))])]),_c('div',{staticClass:"col-auto row items-center"},[_c('pro-button',{attrs:{"label":"System.Button.View","icon":"PROSmart-Docs","to":_vm.getViewMeetingRequestDetail(meetingId)}})],1)])})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }